.Add {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.Add .content {
    width: 100%; 
    max-width: 600px;
    padding: 10px;
}

.Add .fullwidth {
    margin-bottom: 10px;
}

.Add .PageTitle {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: large;
}

.Add  .mapSection {
    width: 100%;
    height: 250px;
    position: relative;

} 

.Add  .Maploading {
position: absolute;
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
border: solid 1px lightgray;
}


.Add .sliderSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Add .helder  {
    font-size: large;
    padding: 10px;


}

.Add .daysCounter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.Add .buttonSection {
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 20px;
}


.Add .iconParent {
    width: 20px;
    height: 20px;
    position: relative;

}
.iconChild {
    position: absolute;
    left: -10px;
    top: -10px;


}