.LoginPage {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    height: 100vh;
   
}

.LoginPage .content {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
     position: relative;
}

.LoginPage .content .Loginloading {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


.LoginPage .content .PhoneTitle {
    font-size: x-large;
    font-weight:bold;
    text-align: center;
    margin-bottom: 20px;
    color: rgb(30, 114, 210);

}
.LoginPage .content  .buttonSection {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
