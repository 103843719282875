.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.mapParent {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.mapParent .fabIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;

}


.mapParent .refreshButton {
  position: absolute;
  left: 5px;
  top: 5px;

}




.markerIconParent {
  width: 30px;
  height: 30px;
  position: relative;
}


 .iconIconButton {
  position: absolute;
  left: -45px;
  top: -70px;
  background-color: blue;
  border-radius: 100px;
  z-index: 50;

}
 .iconButtonImg {
  height: 70px;
  width: 70px;
  
}


.icon {
  position: absolute;
  left: -25px;
  top: -38px;

}


.dialogContent {
  padding: 10px;
  position: relative;

}

.dialogContent .iconButton {
  position: absolute;
  right: 10px;
  top: 10px;

}



.dialogContent .title {
  font-size: x-large;
  font-weight: bold;

}

.dialogContent .description {

  min-height: 100px;
}

.dialogContent .days {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.reportDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

