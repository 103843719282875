body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.mapParent {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.mapParent .fabIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;

}


.mapParent .refreshButton {
  position: absolute;
  left: 5px;
  top: 5px;

}




.markerIconParent {
  width: 30px;
  height: 30px;
  position: relative;
}


 .iconIconButton {
  position: absolute;
  left: -45px;
  top: -70px;
  background-color: blue;
  border-radius: 100px;
  z-index: 50;

}
 .iconButtonImg {
  height: 70px;
  width: 70px;
  
}


.icon {
  position: absolute;
  left: -25px;
  top: -38px;

}


.dialogContent {
  padding: 10px;
  position: relative;

}

.dialogContent .iconButton {
  position: absolute;
  right: 10px;
  top: 10px;

}



.dialogContent .title {
  font-size: x-large;
  font-weight: bold;

}

.dialogContent .description {

  min-height: 100px;
}

.dialogContent .days {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.reportDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.Add {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.Add .content {
    width: 100%; 
    max-width: 600px;
    padding: 10px;
}

.Add .fullwidth {
    margin-bottom: 10px;
}

.Add .PageTitle {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: large;
}

.Add  .mapSection {
    width: 100%;
    height: 250px;
    position: relative;

} 

.Add  .Maploading {
position: absolute;
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
border: solid 1px lightgray;
}


.Add .sliderSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Add .helder  {
    font-size: large;
    padding: 10px;


}

.Add .daysCounter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.Add .buttonSection {
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 20px;
}


.Add .iconParent {
    width: 20px;
    height: 20px;
    position: relative;

}
.iconChild {
    position: absolute;
    left: -10px;
    top: -10px;


}
.LoginPage {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    height: 100vh;
   
}

.LoginPage .content {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
     position: relative;
}

.LoginPage .content .Loginloading {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


.LoginPage .content .PhoneTitle {
    font-size: x-large;
    font-weight:bold;
    text-align: center;
    margin-bottom: 20px;
    color: rgb(30, 114, 210);

}
.LoginPage .content  .buttonSection {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

